<template>
    <v-container fluid>
      <v-row my-auto justify="center">
        <v-dialog v-model="this.$store.getters['Auth/isMailDone']" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Reinitialisation</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-alert icon="mdi-email" prominent text type="info"
                  >Un email vous sera envoyé ! Connecter vous à votre adresse email pour pouvoir
                  procéder à la reinitialisation de votre mot de passe .</v-alert
                >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Votre adresse email "
                      v-model="reset_email"
                      type="email"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close_email">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="reset_email1">Soumettre</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-card>
        <v-row align="center" justify="center">
          <v-card width="100%">
            <v-card-title class="primary--text" :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-1': $vuetify.breakpoint.mdAndUp}">Connexion à votre espace membre</v-card-title>
            <hr class="primary" />
            <v-card-text>
              <form>
                <v-row>
                  <v-col cols="12" md="5" justify="center">
                    <v-img
                    class="mx-auto"
                      src="@/assets/login.jpg"
                      v-if="!this.$store.getters['Auth/loadLogin']"
                      contain
                      width="80%"
                    />
                      <v-skeleton-loader
                        v-if="this.$store.getters['Auth/loadLogin']"
                        class="mx-auto"
                        width="100%"
                        type="image"
                      ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" md="6" class="my-auto">
                    <h1 class="mb-4 hidden-xs-only primary--text" v-if="!this.$store.getters['Auth/loadLogin']">BIENVENUE</h1>
                    <v-skeleton-loader
                        v-if="this.$store.getters['Auth/loadLogin']"
                        class="mb-4 hidden-xs-only primary--text"
                        type="heading"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        v-if="this.$store.getters['Auth/loadLogin']"
                        class="mb-2"
                        type="heading"
                      ></v-skeleton-loader>
                    <v-text-field
                      class="mb-2"
                      v-if="!this.$store.getters['Auth/loadLogin']"
                      v-model="credentials.username"
                      label="Saisir votre nom d’utilisateur ou votre adresse électronique"
                      required
                    ></v-text-field>
                    <v-skeleton-loader
                        v-if="this.$store.getters['Auth/loadLogin']"
                        class="mb-2"
                        type="heading"
                      ></v-skeleton-loader>
                    <v-text-field
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                      class="mb-2"
                      v-if="!this.$store.getters['Auth/loadLogin']"
                      v-model="credentials.password"
                      label="Saisir votre mot de passe"
                      required
                    ></v-text-field>
                    <div>
                                                                <v-skeleton-loader
                        v-if="this.$store.getters['Auth/loadLogin']"
                        class="mb-2"
                        type="actions"
                      ></v-skeleton-loader>
                      <v-btn
                        :block="$vuetify.breakpoint.smAndDown"
                        class="primary mr-4 my-2"
                        small
                        @click="submit"
                        v-if="!this.$store.getters['Auth/loadLogin']"
                        :loading="this.$store.getters['Auth/loadLogin']"
                        >Se connecter</v-btn
                      >
                      <v-btn class="my-1" small :block="$vuetify.breakpoint.smAndDown" outlined @click="reset_email0" v-if="!this.$store.getters['Auth/loadLogin']" color="secondary"
                        >Mot de passe oublié</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-card>
    </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";

export default {
  data: () => ({
    reset_email: "",
    modal_form: false,
    value: String,
    switch1: false,
    legal: [
      { name: "SA", id: 1 },
      { name: "SARL", id: 2 },
      { name: "EURL", id: 3 },
      { name: "SELARL", id: 4 },
      { name: "SAS", id: 5 },
      { name: "SASU", id: 6 },
      { name: "SNC", id: 7 },
      { name: "SCP", id: 8 },
      { name: "AUTRES", id: 9 }
    ],
    credentials: {
      username: "",
      password: ""
    },
    informations: {
      company: {
        name: "",
        jur_form: { name: "SA", id: 1 },
        reg_id_number: "",
        country: "",
        town: "",
        geo_address: "",
        phone: "",
        fax: "",
        email: "",
        pseudo: "",
        password: "",
        password_confirmation: ""
      },
      individu: {
        firstname: "",
        lastname: "",
        profession_title: "",
        country: "",
        town: "",
        address: "",
        phone: "",
        fax: "",
        email: "",
        username: "",
        password: "",
        password_confirmation: ""
      }
    },
    tab: "login",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: null
  }),
  computed: {
    size() {
      const size = {
        xs: 'x-small',
        sm: 'small',
        lg: 'large',
        xl: 'x-large'
      }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {};
    }
  },
  methods: {
    ...mapActions({
      logIn: "Auth/logIn",
      signIn: "Auth/signIn",
      resetEmail: "Auth/resetEmail",
      signInInd: "Auth/signInInd"
    }),
    subscribe() {
      console.log('aa',this.informations.company);
      const data_company = new FormData();
      data_company.append("company_name", this.informations.company.name); // this.informations.company.name
      data_company.append("company_reg_id_number", this.informations.company.reg_id_number); // this.informations.company.reg_id_number
      data_company.append("company_country", this.informations.company.country); // this.credentials.country
      data_company.append("company_town", this.informations.company.town); // this.informations.company.town
      data_company.append("company_phone", this.informations.company.phone); // this.informations.company.phone
      data_company.append("company_address", this.informations.company.geo_address); // this.credentials.geo_address

      data_company.append("company_fax", this.informations.company.fax); // this.informations.company.fax
      data_company.append("legal_form", this.informations.company.jur_form.id); // this.informations.company.jur_form
      data_company.append("company_email", this.informations.company.email); // this.informations.company.email
      data_company.append("password", this.informations.company.password); // this.informations.company.password
      data_company.append("username", this.informations.company.pseudo); // this.informations.company.pseudo
      this.signIn(data_company);
    },
    subscribeInd() {
      console.log(this.informations.individu);
      const data_individu = new FormData();
      // INDIVIDU
      data_individu.append("firstname", this.informations.individu.firstname);
      data_individu.append("lastname", this.informations.individu.lastname);
      data_individu.append("profession_title", this.informations.individu.profession_title);
      data_individu.append("email", this.informations.individu.email);
      data_individu.append("username", this.informations.individu.username);
      data_individu.append("phone", this.informations.individu.phone);
      data_individu.append("fax", this.informations.individu.fax);
      data_individu.append("country", this.informations.individu.country);
      data_individu.append("town", this.informations.individu.town);
      data_individu.append("address", this.informations.individu.address);
      data_individu.append("password", this.informations.individu.password);
      this.signInInd(data_individu);
    },
    reset_email0() {
      this.$store.commit("Auth/SET_MAIL_DONE", true);
    },
    close_email() {
      this.$store.commit("Auth/CLOSE_MAIL", false);
    },
    reset_email1() {
      if (this.reset_email) {
        const email_to_send = new FormData();
        email_to_send.append("email", this.reset_email);
        console.log(email_to_send);
        this.resetEmail(email_to_send);
      } else {
        return Swal.fire({
          title: "Attention!",
          text: "Veuillez saisir votre adresse email !",
          icon: "error",
          confirmButtonText: "OK"
        });
      }
    },
    submit() {
      const data = new FormData();
      data.append("username", this.credentials.username);
      data.append("password", this.credentials.password);
      // console.log(data);
      this.logIn(data);
    }
  }
};
</script>
