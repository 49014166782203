<template>
    <v-container fluid>
      <v-card>
        <v-row align="center" justify="center">
          <v-card width="100%">
            <v-card-title class="primary--text"
              :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-1': $vuetify.breakpoint.mdAndUp}"
              >Formulaire d’inscription du candidat (identification des nouveaux utilisateurs)
              <v-spacer></v-spacer>
              <v-btn outlined small class="my-2" :block="$vuetify.breakpoint.smAndDown" v-if="!switch1" @click="switch1 = !switch1" color="primary"
                >S’inscrire en tant que personne physique</v-btn
              >
              <v-btn v-if="switch1" class="my-2" small :block="$vuetify.breakpoint.smAndDown" @click="switch1 = !switch1" color="primary"
                >S’inscrire en tant que personne morale (cabinets/bureaux  d’étude/entreprises/ sociétés)</v-btn
              >
            </v-card-title>
            <hr class="primary" />
            <v-card-text>
              <form enctype="multipart/form-data">
                <v-row v-if="!switch1" fill-width fill-height class="px-5">
                  <v-col cols="12" md="6" class="p-2">
                    <v-text-field
                      class="mb-2"
                      v-model="informations.company.name"
                      label="Nom du Cabinet / Société / Entreprise *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.reg_id_number"
                      label="Numéro du registre de commerce *"
                      required
                    ></v-text-field>
                    <v-select
                      v-model="informations.company.jur_form"
                      :hint="`${informations.company.jur_form.name}`"
                      :items="legal"
                      item-text="name"
                      item-value="id"
                      label="Form Juridique"
                      persistent-hint
                      return-object
                      single-line
                    ></v-select>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.country"
                      label="Pays *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.town"
                      label="Ville *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.geo_address"
                      label="Adresse géographique *"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <vue-tel-input-vuetify
                      v-bind="bindProps"
                      placeholder="Numéro de téléphone"
                      class="mb-1"
                      v-model="informations.company.phone"
                    ></vue-tel-input-vuetify>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.fax"
                      label="Fax"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      type="email"
                      v-model="informations.company.email"
                      label="Adresse électronique (e-mail) du Cabinet/Société/Entreprise *"
                      required
                    ></v-text-field>
                                                            <v-select
                      v-model="informations.company.activity"
                      :items="activity_sector"
                      item-text="name"
                      multiple
                      item-value="id"
                      label="Secteur d'activité"
                      persistent-hint
                      return-object
                      single-line
                    ></v-select>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.pseudo"
                      label="Identifiant (pseudo) *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.company.password"
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                      label="Mot de passe *"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="switch1">
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mb-2"
                      v-model="informations.individu.firstname"
                      label="Nom de l'individu (personne morale) *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.lastname"
                      label="Prénoms l'individu (personne morale) *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.profession_title"
                      label="Fonction *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.country"
                      label="Pays *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.town"
                      label="Ville *"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.address"
                      label="Adresse géographique *"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                   <vue-tel-input-vuetify
                      v-bind="bindProps"
                      placeholder="Numéro de téléphone"
                      class="mb-1"
                      v-model="informations.individu.phone"
                    ></vue-tel-input-vuetify>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.fax"
                      label="Fax"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="mb-1"
                      type="email"
                      v-model="informations.individu.email"
                      label="Adresse électronique (e-mail) *"
                      required
                    ></v-text-field>
                                                            <v-select
                      v-model="informations.individu.activity"
                      :items="activity_sector"
                      item-text="name"
                      multiple
                      item-value="id"
                      label="Secteur d'activité"
                      persistent-hint
                      return-object
                      single-line
                    ></v-select>
                    <v-text-field
                      class="mb-1"
                      v-model="informations.individu.username"
                      label="Identifiant (pseudo) *"
                      required
                    ></v-text-field>
                    <v-text-field
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                      class="mb-1"
                      v-model="informations.individu.password"
                      label="Mot de passe *"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    class="primary mr-4"
                    :loading="this.$store.getters['Auth/loadSignup']"
                    @click="subscribe()"
                    small
                    right
                    :block="$vuetify.breakpoint.smAndDown"
                    v-if="!switch1"
                    >VALIDER L’INSCRIPTION</v-btn
                  >
                  <v-btn
                    class="primary mr-4"
                    :loading="this.$store.getters['Auth/loadSignup']"
                    @click="subscribeInd()"
                    small
                    right
                    :block="$vuetify.breakpoint.smAndDown"
                    v-if="switch1"
                    >VALIDER L’INSCRIPTION</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-card>
    </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  data: () => ({
    value: String,
    reset_email: "",
    modal_form: false,
    switch1: false,
    legal: [
      { name: "SA", id: 1 },
      { name: "SARL", id: 2 },
      { name: "EURL", id: 3 },
      { name: "SELARL", id: 4 },
      { name: "SAS", id: 5 },
      { name: "SASU", id: 6 },
      { name: "SNC", id: 7 },
      { name: "SCP", id: 8 },
      { name: "AUTRES", id: 9 }
    ],
    activity_sector: [
      { name: "Travaux", id: 1 },
      { name: "Fournitures", id: 2 },
      { name: "Prestations Intellectuelle", id: 3 },
    ],
    country: null,
    bindProps: {
      mode: "international",
      required: true,
      label: "Numéro de téléphone",
      enabledCountryCode: true,
      validCharactersOnly: true,
      enabledFlags: true,
      autocomplete: "off",
      name: "telephone",
      maxLen: 25,
      inputOptions: {
        showDialCode: true
      }
    },
    telValid: null,
    credentials: {
      username: "",
      password: ""
    },
    informations: {
      company: {
        name: "",
        jur_form: { name: "SA", id: 1 },
        activity: [],
        reg_id_number: "",
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: "",
        password: "",
        password_confirmation: ""
      },
      individu: {
        firstname: "",
        lastname: "",
        profession_title: "",
        country: "",
        town: "",
        address: "",
        phone: null,
        activity: [],
        fax: "",
        email: "",
        username: "",
        password: "",
        password_confirmation: ""
      }
    },
    tab: "login",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: null
  }),
  computed: {
    size() {
      const size = {
        xs: 'x-small',
        sm: 'small',
        lg: 'large',
        xl: 'x-large'
      }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {};
    }
  },
  methods: {
    ...mapActions({
      logIn: "Auth/logIn",
      signIn: "Auth/signIn",
      resetEmail: "Auth/resetEmail",
      signInInd: "Auth/signInInd"
    }),
    getPhone(e) {
      if (e.isValid) {
        console.log(e);
        this.telValid = e.number.international;
        console.log(e);
      }
    },
    subscribe() {
      const data_company = new FormData();
      let sec_ids = this.informations.company.activity.map(a => a.id);
      data_company.append("company_name", this.informations.company.name); // this.informations.company.name
      data_company.append("company_reg_id_number", this.informations.company.reg_id_number); // this.informations.company.reg_id_number
      data_company.append("company_country", this.informations.company.country); // this.credentials.country
      data_company.append("company_town", this.informations.company.town); // this.informations.company.town
      data_company.append("company_phone", this.informations.company.phone); // this.informations.company.phone
      data_company.append("company_address", this.informations.company.geo_address); // this.credentials.geo_address

      data_company.append("activity_secteurs", sec_ids.toString()); // t

      data_company.append("company_fax", this.informations.company.fax); // this.informations.company.fax
      data_company.append("legal_form", this.informations.company.jur_form.id); // this.informations.company.jur_form
      data_company.append("company_email", this.informations.company.email); // this.informations.company.email
      data_company.append("password", this.informations.company.password); // this.informations.company.password
      data_company.append("username", this.informations.company.pseudo); // this.informations.company.pseudo
      this.signIn(data_company);
    },
    subscribeInd() {
      const data_individu = new FormData();
      let sec_ids = this.informations.individu.activity.map(a => a.id);
      // INDIVIDU
      data_individu.append("firstname", this.informations.individu.firstname);
      data_individu.append("lastname", this.informations.individu.lastname);
      data_individu.append("profession_title", this.informations.individu.profession_title);
      data_individu.append("email", this.informations.individu.email);
      data_individu.append("username", this.informations.individu.username);
      data_individu.append("phone", this.informations.individu.phone);
      data_individu.append("activity_secteurs", sec_ids.toString()); // t
      data_individu.append("fax", this.informations.individu.fax);
      data_individu.append("country", this.informations.individu.country);
      data_individu.append("town", this.informations.individu.town);
      data_individu.append("address", this.informations.individu.address);
      data_individu.append("password", this.informations.individu.password);
      this.signInInd(data_individu);
    },
    reset_email0() {
      this.$store.commit("Auth/SET_MAIL_DONE", true);
    },
    close_email() {
      this.$store.commit("Auth/CLOSE_MAIL", false);
    },
    reset_email1() {
      if (this.reset_email) {
        const email_to_send = new FormData();
        email_to_send.append("email", this.reset_email);
        console.log(email_to_send);
        this.resetEmail(email_to_send);
      } else {
        return Swal.fire({
          title: "Attention!",
          text: "Veuillez saisir votre adresse email !",
          icon: "error",
          confirmButtonText: "OK"
        });
      }
    },
    submit() {
      const data = new FormData();
      data.append("username", this.credentials.username);
      data.append("password", this.credentials.password);
      this.logIn(data);
    }
  }
};
</script>
<style scoped>
.customsel {
  width: 100%;
  margin-bottom: 1.3em;
  position: relative;
  font-family: inherit;
  background-color: transparent;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
/* Remove focus */
.customsel:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.589);
}
.select .customsel {
  appearance: none;
  -webkit-appearance: none;
}
.customsel:after {
  width: 100%;
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}
/* LABEL ======================================= */
.customsel-label {
  color: rgba(0, 0, 0, 0.26);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.customsel-text:focus ~ .customsel-label,
.customsel-text:valid ~ .customsel-label {
  color: #2f80ed;
  top: -20px;
  transition: 0.2s ease all;
  font-size: 14px;
}

/* BOTTOM BARS ================================= */
.customsel-bar {
  position: relative;
  display: block;
  width: 350px;
}

.customsel-bar:before,
.customsel-bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #2f80ed;
  transition: 0.2s ease all;
}

.customsel-bar:before {
  left: 50%;
}

.customsel-bar:after {
  right: 50%;
}

/* active state */
.customsel-text:focus ~ .customsel-bar:before,
.customsel-text:focus ~ .customsel-bar:after {
  width: 50%;
}
</style>
