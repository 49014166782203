<template>
  <v-container fluid>
    <v-row>
      <v-dialog eager v-model="dialog" width="900px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>
              <h4 class="ml-2">
                {{ Tdr_Ob.fileName }}
              </h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text style="height: 62em !important; overflow: hidden">
            <VueDocPreview
              style="height: 100%"
              v-show="
                Tdr_Ob.fileType == 'doc' ||
                  Tdr_Ob.fileType == 'docx' ||
                  Tdr_Ob.fileType == 'xls' ||
                  Tdr_Ob.fileType == 'xlsx' ||
                  Tdr_Ob.fileType == 'XLS' ||
                  Tdr_Ob.fileType == 'XLSX'
              "
              :value="Tdr_Ob.fileLink"
              type="office"
            />
            <vue-friendly-iframe
              style="height: 100%"
              v-show="Tdr_Ob.fileType == 'pdf'"
              :src="Tdr_Ob.fileLink"
              class="w-62em"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
        <v-card>
          <v-card-title>Statistiques des publications</v-card-title>
          <v-card-text>
            <v-row v-show="!infoHome.data">
              <v-col cols="6" class="pb-2">
                <v-card flat>
                  <v-row class="no-gutters">
                    <div class="col-auto">
                      <div class="cyan fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 cyan--text">
                      <h5 class="text-uppercase">PPM</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1 v-text="0"></h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6" class="pb-2">
                <v-card flat>
                  <v-row class="no-gutters">
                    <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-uppercase">AVIS</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1 v-text="0"></h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" class="pb-2">
                <v-card flat>
                  <v-row class="no-gutters">
                    <div class="col-auto">
                      <div class="success fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 success--text">
                      <h5 class="text-uppercase">DOSSIERS</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1 v-text="0"></h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-show="infoHome.data">
              <v-col cols="6" class="pb-2">
                <v-card flat>
                  <v-row class="no-gutters">
                    <div class="col-auto">
                      <div class="cyan fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 cyan--text">
                      <h5 class="text-uppercase">PPM</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1 v-text="infoHome.data.ppms"></h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6" class="pb-2">
                <v-card flat>
                  <v-row class="no-gutters">
                    <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-uppercase">AVIS</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1 v-text="infoHome.data.notices"></h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" class="pb-2">
                <v-card flat>
                  <v-row class="no-gutters">
                    <div class="col-auto">
                      <div class="success fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 success--text">
                      <h5 class="text-uppercase">DOSSIERS</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1 v-text="infoHome.data.folders"></h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <!--                 <v-col cols="6" class="pb-2">
                  <v-card flat>
                    <v-row class="no-gutters">
                      <div class="col-auto">
                        <div class="indigo fill-height">&nbsp;</div>
                      </div>
                      <div class="col pa-3 py-4 indigo--text">
                        <h5 class="text-uppercase">Visites</h5>
                        <v-divider class="my-1"></v-divider>
                        <h1 v-text="this.$store.getters['Auth/get_all_legal_form'].number_visitor.number_visitor"></h1>
                      </div>
                    </v-row>
                  </v-card>
                </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" xl="9">
        <v-expansion-panels v-show="!infoHome.data" hover ripple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Mot de bienvenue</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              VIDE
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Présentation (Acheteur / Autorité Contractante)</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              VIDE
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Domaine d’intervention (Acheteur / Autorité Contractante)</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              VIDE
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Missions / Composantes</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              VIDE
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text"
                >Partenaires au développement</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              VIDE
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels v-show="infoHome.data" hover ripple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Mot de bienvenue</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-html="infoHome.data.data[0].welcomeWord"
            ></v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Présentation (Acheteur / Autorité Contractante)</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-html="infoHome.data.data[0].presentation"
            ></v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Domaine d’intervention (Acheteur / Autorité Contractante)</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-html="infoHome.data.data[0].projectArea"
            ></v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold primary--text">Missions / Composantes</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-html="infoHome.data.data[0].purpose"
            ></v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class=" font-weight-bold primary--text"
                >Partenaires au développement</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-html="infoHome.data.data[0].partner"
            ></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card class="mt-4" v-show="infoHome.data">
          <v-card-title>
            <h5 class="font-weight-bold primary--text">
              Organigramme (Acheteur / Autorité Contractante)
            </h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-show="infoHome.data.organigramme.length">
            <organization-chart :datasource="infoHome.data.organigramme[0]"></organization-chart>
          </v-card-text>
          <v-card-text v-show="!infoHome.data.organigramme.length">
            <organization-chart :datasource="ds"></organization-chart>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            <h5 class=" font-weight-bold primary--text">
              Documentations (Acheteur / Autorité Contractante)
            </h5>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row v-show="infoHome.data">
                <v-col v-for="doc in infoHome.data.docs" :key="doc.id" cols="6" md="2">
                  <v-card>
                    <v-card-title>
                      <h6 class="text-truncate">{{ doc.fileName }}</h6>
                    </v-card-title>
                    <v-card-text v-show="doc.fileType == 'pdf'">
                      <v-list-item @click="downloadWithVueResource(doc)" target="_blank">
                        <v-img
                          src="@/assets/pdf.png"
                          lazy-src="@/assets/pdf.png"
                          aspect-ratio="1"
                        ></v-img>
                      </v-list-item>
                    </v-card-text>
                    <v-card-text v-show="doc.fileType == 'docx' || doc.fileType == 'doc'">
                      <v-list-item @click="downloadWithVueResource(doc)" target="_blank">
                        <v-img
                          src="@/assets/word.png"
                          lazy-src="@/assets/word.png"
                          aspect-ratio="1"
                        ></v-img>
                      </v-list-item>
                    </v-card-text>
                    <v-card-text
                      v-show="
                        doc.fileType == 'xls' ||
                          doc.fileType == 'xlsx' ||
                          doc.fileType == 'XLS' ||
                          doc.fileType == 'XLSX'
                      "
                    >
                      <v-list-item @click="downloadWithVueResource(doc)" target="_blank">
                        <v-img
                          src="@/assets/excel.png"
                          lazy-src="@/assets/excel.png"
                          aspect-ratio="1"
                        ></v-img>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-show="!infoHome.data">
                <div>PAS DE DOCUMENTS</div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "vue-organization-chart/dist/orgchart.css";
import { mapGetters } from "vuex";

export default {
  components: {
    // OrganizationChart,
    // VueDocPreview
    OrganizationChart: () => import('vue-organization-chart'),
    VueDocPreview: () => import('vue-doc-preview'),
  },
  data() {
    return {
      Tdr_Ob: "",
      dialog: false,
      ds: {
        id: "1",
        name: "Empty",
        title: "General manager",

        children: [
          {
            id: "2",
            name: "Empty",
            title: "Head of HR"
          },
          {
            id: "3",
            name: "Empty",
            title: "Head of Technique"
          }
        ]
      },
      tab: null,
      items: [
        {
          name: "Mot de bienvenue",
          content:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from  by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. "
        },
        {
          name: "Présentation (Acheteur / Autorité Contractante)",
          content: "lorem ipsum dolorem"
        },
        {
          name: "Domaine d’intervention (Acheteur / Autorité Contractante)",
          content: "lorem ipsum dolorem"
        },
        {
          name: "Missions / Composantes",
          content: "lorem ipsum dolorem"
        },
        {
          name: "Partenaires au développement",
          content: "lorem ipsum dolorem"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      infoHome: "Auth/get_all_cnfig_home",
      getHomeDocs: "Auth/getHomeDocs"
    })
  },
  methods: {
    assignDoc(doc) {
      this.Tdr_Ob = doc;
      this.dialog = !this.dialog;
    },
    async downloadWithVueResource(doc) {
      await this.assignDoc(doc);
    }
  },
  created() {
    this.$store.dispatch('Auth/getAllConfigHome');
    this.$store.dispatch('Auth/getHomeChart');
  }
};
</script>

<style>
h4 {
  font-size: medium;
}
.v-list-item__content > * {
  font-size: smaller;
}
.v-expansion-panel-content {
  display: block;
  padding: 0.1em 2em;
}
.orgchart-container {
  border: none;
}
.orgchart .node .title {
  background-color: rgb(3, 89, 173);
}
.orgchart .lines .downLine {
  background-color: rgb(63, 211, 199);
}
.orgchart .lines .topLine {
  border-top: 2px solid rgb(63, 211, 199);
}
.orgchart .lines .rightLine {
  border-right: 1px solid rgb(63, 211, 199);
}
.v-application .title {
  font-size: 0.8rem !important;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
