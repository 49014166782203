<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="12" md="10">
        <v-card width="100%">
          <v-card-title class="primary--text" :class="{'headline': $vuetify.breakpoint.smAndDown, 'display-1': $vuetify.breakpoint.mdAndUp}">Formulaire de demande de  renseignement/prise de contact</v-card-title>
          <hr class="primary mb-5" />
          <v-card-text>
            <v-textarea
              v-model="message.content"
              filled
              name="input-7-4"
              label="Saisir votre message ici"
            ></v-textarea>
            <v-text-field
              filled
              label="Saisir l’objet du message"
              v-model="message.object"
              persistent-hint
            ></v-text-field>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="message.sender_full_name"
                  label="Saisir le nom complet de l’expéditeur (nom & prénoms)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="message.sender_email"
                  label="Saisir l’email de l’expéditeur"
                  type="email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="send" small color="primary" :block="$vuetify.breakpoint.smAndDown">
              Envoyer le message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    info: false,
    showMessage: [],
    message: {
      object: "",
      content: "",
      sender_email: "",
      sender_full_name: ""
    },
    password: "Password",
    bread: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Messagerie",
        disabled: true
      }
    ],
    show: false,
    marker: true
  }),

  methods: {
    ...mapActions({
      send_message: "Auth/send_message"
    }),
    showMess(item) {
      this.info = true;
      this.showMessage = item;
    },
    send() {
      const data = new FormData();
      data.append("object", this.message.object);
      data.append("sender_email", this.message.sender_email);
      data.append("sender_full_name", this.message.sender_full_name);
      data.append("content", this.message.content);
      if (this.message.object && this.message.sender_email && this.message.sender_full_name) {
        this.send_message(data);
      }
    }
  }
};
</script>
