<template>
  <v-container fluid>
    <v-container class="mt-5em">
      <v-card flat>
        <v-card-title class="primary--text display-1"
          >Reinitialiser votre mot de passe</v-card-title
        >
        <hr class="primary" />
        <v-card-text>
          <ValidationObserver ref="observer">
            <form>
              <v-row class="mx-auto my-5 flex align-center">
                <v-col cols="12" md="5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title>Recommadation sur le choix</v-list-item-title>
                      <v-divider class="my-2"></v-divider>
                      <v-list-item-subtitle
                        >Plus votre mot de passe sera long, plus il sera difficile à craquer ou à
                        deviner.</v-list-item-subtitle
                      >
                      <v-divider class="my-2" inset></v-divider>
                      <v-list-item-subtitle
                        >Pour bien sécuriser votre mot-de-passe, celui ci doit contenir au moins 8
                        caractères et être composé de chiffres, de lettres minuscules et de
                        majuscules.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="7" class="align-center mx-auto my-auto">
                  <v-text-field
                    counter
                    minlength="6"
                    class="mb-2"
                    v-model="password.secret"
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    label="Votre nouveau mot de passe"
                    required
                  ></v-text-field>
                  <v-text-field
                    counter
                    minlength="6"
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    class="mb-2"
                    v-model="password.confirmation"
                    label="Confirmer votre nouveau mot de passe"
                    required
                  ></v-text-field>
                  <v-btn class="primary mr-4" @click="reset">Reinitialiser le mot de passe</v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  props: ["token"],
  data: () => ({
    value: String,
    switch1: false,
    password: {
      secret: "",
      confirmation: ""
    }
  }),
  methods: {
    ...mapActions({
      resetPassword_byEmail: "Auth/resetPassword_byEmail"
    }),
    reset() {
      const data = new FormData();
      console.log(this.token, this.password);
      data.append("token", this.token);
      data.append("password", this.password.secret);
      data.append("confirm", this.password.confirmation);
      if (
        this.password.confirmation === this.password.secret &&
        this.password.confirmation.length >= 6
      ) {
        this.resetPassword_byEmail(data);
      } else {
        Swal.fire({
          title: "Attention !",
          text: "Les mots de passe saisis ne respectent pas la norme ",
          icon: "error",
          confirmButtonText: "Ressayer"
        });
      }
    }
  }
};
</script>
